import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _821334da = () => interopDefault(import('../pages/check-email.vue' /* webpackChunkName: "pages/check-email" */))
const _6961f865 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0fee1cbe = () => interopDefault(import('../pages/logout.vue' /* webpackChunkName: "pages/logout" */))
const _5945de83 = () => interopDefault(import('../pages/medications/index.vue' /* webpackChunkName: "pages/medications/index" */))
const _0dc1503d = () => interopDefault(import('../pages/password-updated.vue' /* webpackChunkName: "pages/password-updated" */))
const _a31f48f0 = () => interopDefault(import('../pages/paylinks/index.vue' /* webpackChunkName: "pages/paylinks/index" */))
const _aaeba150 = () => interopDefault(import('../pages/prescriptions/index.vue' /* webpackChunkName: "pages/prescriptions/index" */))
const _3e3a0a57 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _16c48894 = () => interopDefault(import('../pages/registered.vue' /* webpackChunkName: "pages/registered" */))
const _786cf0fa = () => interopDefault(import('../pages/resend-confirmation.vue' /* webpackChunkName: "pages/resend-confirmation" */))
const _3f89d50d = () => interopDefault(import('../pages/reset-password.vue' /* webpackChunkName: "pages/reset-password" */))
const _78445052 = () => interopDefault(import('../pages/settings.vue' /* webpackChunkName: "pages/settings" */))
const _752428a0 = () => interopDefault(import('../pages/settings/close-account.vue' /* webpackChunkName: "pages/settings/close-account" */))
const _3ca2e39c = () => interopDefault(import('../pages/settings/export.vue' /* webpackChunkName: "pages/settings/export" */))
const _56141004 = () => interopDefault(import('../pages/settings/hospitals/index.vue' /* webpackChunkName: "pages/settings/hospitals/index" */))
const _7a38e634 = () => interopDefault(import('../pages/settings/options.vue' /* webpackChunkName: "pages/settings/options" */))
const _a8b6b40e = () => interopDefault(import('../pages/settings/pharmacies.vue' /* webpackChunkName: "pages/settings/pharmacies" */))
const _41af0d28 = () => interopDefault(import('../pages/settings/security.vue' /* webpackChunkName: "pages/settings/security" */))
const _38066c33 = () => interopDefault(import('../pages/settings/user.vue' /* webpackChunkName: "pages/settings/user" */))
const _a737cf5c = () => interopDefault(import('../pages/settings/hospitals/new.vue' /* webpackChunkName: "pages/settings/hospitals/new" */))
const _f6c4ee22 = () => interopDefault(import('../pages/settings/hospitals/_id/index.vue' /* webpackChunkName: "pages/settings/hospitals/_id/index" */))
const _2d3b7f06 = () => interopDefault(import('../pages/settings/hospitals/_id/edit.vue' /* webpackChunkName: "pages/settings/hospitals/_id/edit" */))
const _291d3c70 = () => interopDefault(import('../pages/embed/rx.vue' /* webpackChunkName: "pages/embed/rx" */))
const _be7c06b4 = () => interopDefault(import('../pages/prescriptions/new.vue' /* webpackChunkName: "pages/prescriptions/new" */))
const _baacacbe = () => interopDefault(import('../pages/redirect/new.vue' /* webpackChunkName: "pages/redirect/new" */))
const _c08b5564 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _a68c8b80 = () => interopDefault(import('../pages/paylinks/_slug.vue' /* webpackChunkName: "pages/paylinks/_slug" */))
const _d2ba4dba = () => interopDefault(import('../pages/prescriptions/_signature.vue' /* webpackChunkName: "pages/prescriptions/_signature" */))
const _e37a8af6 = () => interopDefault(import('../pages/new-password/_uid/_token.vue' /* webpackChunkName: "pages/new-password/_uid/_token" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/check-email",
    component: _821334da,
    name: "check-email"
  }, {
    path: "/login",
    component: _6961f865,
    name: "login"
  }, {
    path: "/logout",
    component: _0fee1cbe,
    name: "logout"
  }, {
    path: "/medications",
    component: _5945de83,
    name: "medications"
  }, {
    path: "/password-updated",
    component: _0dc1503d,
    name: "password-updated"
  }, {
    path: "/paylinks",
    component: _a31f48f0,
    name: "paylinks"
  }, {
    path: "/prescriptions",
    component: _aaeba150,
    name: "prescriptions"
  }, {
    path: "/register",
    component: _3e3a0a57,
    name: "register"
  }, {
    path: "/registered",
    component: _16c48894,
    name: "registered"
  }, {
    path: "/resend-confirmation",
    component: _786cf0fa,
    name: "resend-confirmation"
  }, {
    path: "/reset-password",
    component: _3f89d50d,
    name: "reset-password"
  }, {
    path: "/settings",
    component: _78445052,
    name: "settings",
    children: [{
      path: "close-account",
      component: _752428a0,
      name: "settings-close-account"
    }, {
      path: "export",
      component: _3ca2e39c,
      name: "settings-export"
    }, {
      path: "hospitals",
      component: _56141004,
      name: "settings-hospitals"
    }, {
      path: "options",
      component: _7a38e634,
      name: "settings-options"
    }, {
      path: "pharmacies",
      component: _a8b6b40e,
      name: "settings-pharmacies"
    }, {
      path: "security",
      component: _41af0d28,
      name: "settings-security"
    }, {
      path: "user",
      component: _38066c33,
      name: "settings-user"
    }, {
      path: "hospitals/new",
      component: _a737cf5c,
      name: "settings-hospitals-new"
    }, {
      path: "hospitals/:id",
      component: _f6c4ee22,
      name: "settings-hospitals-id"
    }, {
      path: "hospitals/:id/edit",
      component: _2d3b7f06,
      name: "settings-hospitals-id-edit"
    }]
  }, {
    path: "/embed/rx",
    component: _291d3c70,
    name: "embed-rx"
  }, {
    path: "/prescriptions/new",
    component: _be7c06b4,
    name: "prescriptions-new"
  }, {
    path: "/redirect/new",
    component: _baacacbe,
    name: "redirect-new"
  }, {
    path: "/",
    component: _c08b5564,
    name: "index"
  }, {
    path: "/paylinks/:slug",
    component: _a68c8b80,
    name: "paylinks-slug"
  }, {
    path: "/prescriptions/:signature",
    component: _d2ba4dba,
    name: "prescriptions-signature"
  }, {
    path: "/new-password/:uid?/:token?",
    component: _e37a8af6,
    name: "new-password-uid-token"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
